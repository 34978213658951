<template>
  <b-modal
    id="export-issues-modal"
    title="Export Issue Table"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    @ok="generateTable"
    @shown="onShown"
  >
    <div class="mb-1">
      <h6>File format</h6>
      <b-form-select v-model="filetype" :options="formatOptions" />
    </div>
    <b-row v-if="loaded">
      <b-col>
        <b-table
          :items="issues"
          :fields="issueFields"
          small
          hover
          responsive
          no-border-collapse
          sticky-header="80vh"
          class="text-left"
        >
          <template v-slot:cell(name)="{ item }">
            {{ item.name || '-' }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-overlay v-else show opacity="0">
      <template v-slot:overlay>
        <h1 style="display: inline-block">
          Loading Issues...
        </h1>
        <div class="text-center mt-25">
          <b-spinner />
        </div>
      </template>
      <div style="height: 50vh;" />
    </b-overlay>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Exporting...
        </span>
        <span v-else>
          Export
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ExportIssuesReport',
  data() {
    return {
      parentSpec: null,
      childSpec: null,
      loading_status: false,
      loaded: false,
      direction: 'after',
      filetype: 'CSV',
      formatOptions: ['CSV', 'XLSX'],
      issues: [],
      issueFields: [
        {
          key: 'display_id', label: 'Identification', sortable: true, thStyle: { width: '18%' }, thClass: 'position-sticky',
        },
        {
          key: 'name', sortable: true, thClass: 'position-sticky',
        },
        {
          key: 'classification', sortable: true, thStyle: { width: '20%' }, thClass: 'position-sticky',
        },
        {
          key: 'severity', sortable: true, thStyle: { width: '15%' }, thClass: 'position-sticky',
        },
        {
          key: 'status', sortable: true, thStyle: { width: '15%' }, thClass: 'position-sticky',
        },
      ],
    }
  },
  methods: {
    onShown() {
      this.loaded = false
      const params = {
        model: this.$store.state.model.id,
      }
      this.$http
        .get('/api/v2/issues/get_issues_simple', { params })
        .then(({ data }) => {
          console.log('issues: ', data)
          this.issues = data
          this.loading_status = false
          this.loaded = true
        })
    },
    async generateTable(evt) {
      // This prevents the modal from closing prematurely
      evt.preventDefault()
      this.loading_status = true

      const filetype = this.filetype.toLowerCase() || 'csv'
      const columns = []
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      // Create and append data to the json file
      try {
        const response = await this.$coreService.post(
          '/v1/issues/report',
          columns,
          {
            headers: { 'model-id': this.$store.state.model.id },
            params: { filetype },
            responseType: 'blob',
          },
        )
        const fileName = `issues_${now}.${filetype}`
        // Create the URL, then an 'a' element in the DOM and then click it to trigger an automatic file download
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        link.click()
        link.remove()

        this.loading_status = false
        this.$bvModal.hide('export-issues-modal')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Issues exported',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.error(`Error downloading ${filetype.toUpperCase()}: ${error}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to export Issues',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style>

</style>
