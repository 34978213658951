import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchIssues(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/api/v2/issues', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/apps/email/update-emails-label', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/apps/email/paginate-email', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
