<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="$emit('close-issue-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ selectedIssue.display_id }}
          <span class="mx-75">:</span>
          <b-badge
            :variant="resolveStatusColor(selectedIssue.status)"
          >
            {{ selectedIssue.status }}
          </b-badge>
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <b-badge
          pill
          class="mr-50"
          :variant="`${resolveSeverityColor(selectedIssue.severity)}`"
        >
          <span class="font-medium-1">
            {{ selectedIssue.severity }}
          </span>
        </b-badge>

        <b-badge pill variant="light-primary" class="mr-50">
          <span class="font-medium-1">
            <feather-icon
              :icon="resolveClassificationIcon(selectedIssue.classification)"
              style="margin-top: 0.1rem"
            />
            {{ resolveClassificationDescriptor(selectedIssue.classification) }}
          </span>
        </b-badge>

        <!-- Trigger Issue edit mode -->
        <feather-icon
          id="issue-edit-button"
          v-b-tooltip.hover.top="'Edit Issue'"
          icon="EditIcon"
          class="ml-75"
          :class="editMode ? 'text-muted' : 'text-success cursor-pointer'"
          size="17"
          @click="editMode = true"
        />

        <!-- Go to Issue history -->
        <font-awesome-icon v-b-tooltip.hover.top="'Issue History'"
                           :icon="['fas', 'timeline']"
                           right
                           class="ml-75 text-warning cursor-pointer"
                           @click="issueHistory"
        />

        <!-- Show Previous Issue -->
        <feather-icon
          icon="ChevronLeftIcon"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedIssueMeta.hasPreviousIssue}"
          @click="$emit('change-opened-issue', 'previous')"
        />

        <!-- Show Next Issue -->
        <feather-icon
          icon="ChevronRightIcon"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedIssueMeta.hasNextIssue}"
          @click="$emit('change-opened-issue', 'next')"
        />
      </div>
    </div>

    <!-- Issue Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <!-- Issue details card -->
      <b-row>
        <b-col cols="12">
          <issue-details-card
            ref="issueInfoCard"
            :edit-mode="editMode"
            :issue="selectedIssue"
            @updated="$emit('updated')"
            @set-edit-mode="setEditMode"
          />
        </b-col>
      </b-row>

      <!-- Associated requirements/entities/behaviours -->
      <h5>Associated Items</h5>
      <b-card no-body>
        <app-collapse type="border">
          <app-collapse-item title="Requirements">
            <template #header>
              <b-badge class="mr-1" variant="light-primary">
                {{ selectedIssue.requirements ? selectedIssue.requirements.length : '0' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Requirements
              </span>
            </template>
            <b-button
              v-b-hover="hoverHandlerReqAssocButton"
              v-b-modal="'associator-generic-requirements'"
              :variant="editRequirementAssociationsButton"
              class="mb-1"
              block
            >
              <feather-icon
                icon="Edit2Icon"
                class="text-success"
              />
              Edit Requirement Associations
            </b-button>

            <div class="mb-1">
              <div v-for="req in selectedIssue.requirements" :key="req.id" class="mb-2">
                <issue-requirement-card :requirement="req" />
              </div>
              <div v-if="!selectedIssue.requirements || selectedIssue.requirements.length === 0" class="mb-2">
                <em>No associated requirements...</em>
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item title="Entities">
            <template #header>
              <b-badge class="mr-1" variant="light-primary">
                {{ selectedIssue.components ? selectedIssue.components.length : '0' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Entities
              </span>
            </template>

            <b-button
              v-b-hover="hoverHandlerEntAssocButton"
              v-b-modal="'associator-generic-entities'"
              :variant="editEntityAssociationsButton"
              class="mb-1"
              block
            >
              <feather-icon
                icon="Edit2Icon"
                class="text-primary"
              />
              Edit Entity Associations
            </b-button>

            <div class="mb-1">
              <div v-for="comp in selectedIssue.components" :key="comp.id" class="mb-2">
                <issue-entity-card :entity="comp" />
              </div>
              <div v-if="!selectedIssue.components || selectedIssue.components.length === 0" class="mb-2">
                <em>No associated entities...</em>
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item title="Behaviours">
            <template #header>
              <b-badge class="mr-1" variant="light-primary">
                {{ selectedIssue.behaviours ? selectedIssue.behaviours.length : '0' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Behaviours
              </span>
            </template>

            <div class="mb-1">
              <div v-for="bn in selectedIssue.behaviours" :key="bn.id" class="mb-2">
                <issue-behaviour-card :behaviour="bn" />
              </div>
              <div v-if="!selectedIssue.behaviours || selectedIssue.behaviours.length === 0" class="mb-2">
                <em>No associated behaviours...</em>
              </div>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <!-- Comments card -->
      <h5>Comments</h5>
      <b-row>
        <b-col cols="12">
          <b-card>
            <comment-card
              :parent-id="selectedIssue.id"
              :parent-label="'Issue'"
            />
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

    <issue-requirement-allocate />
    <issue-component-allocate />
    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      :all-items="allRequirements"
      :associated-items="associatedRequirements"
      @associated="associateRequirements"
    />
    <!-- Components -->
<!--    <associator-generic-->
<!--      name="Entities"-->
<!--      :all-items="allEntities"-->
<!--      :associated-items="associatedEntities"-->
<!--      @associated="associateEntitiesIssues"-->
<!--    />-->
    <GenericAssociator
      id="associator-generic-entities"
      :fetch-filter-fn="fetchSharedModels"
      :fetch-fn="modelId => fetchComponents(modelId, '')"
      :update-fn="payload => linkComponent(payload)"
      :remove-fn="payload => unlinkComponent(payload)"
      :initial-prefilter="model"
      :initial-list="associatedEntities"
      :instant-save="true"
      prefilter-label="name"
      type-name="Entities"
    >
      <template #left="cpt">
        <b-badge class="mr-1" variant="info">
          ({{ cpt.labels ? cpt.labels[1] : 'Entity' }})
        </b-badge>
        &nbsp;
        <abbr :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </abbr>
      </template>
      <template #right="{ cpt }">
        <b-badge v-if="cpt.model !== model.id" class="mr-1" variant="primary" :title="`${cpt.model}`">
          <feather-icon
            icon="ExternalLinkIcon"
            size="16"
          />
        </b-badge>
        <b-badge class="mr-1" variant="info">
          {{ cpt.labels ? cpt.labels[1] : 'Entity' }}
        </b-badge>
        <abbr :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ ' ' }}{{ cpt.name }} </abbr>
      </template>
    </GenericAssociator>
  </div>
</template>

<script>
// Directives and Template stuff
import {
  BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  onUnmounted,
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios'

// Modals
import IssueRequirementAllocate from '@/components/Issues/Modals/IssueRequirementAllocate.vue'
import IssueComponentAllocate from '@/components/Issues/Modals/IssueComponentAllocate.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Components
import CommentCard from '@/components/Comments/CommentCard.vue'
import IssueRequirementCard from '@/components/Issues/IssueRequirementCard.vue'
import IssueEntityCard from '@/components/Issues/IssueEntityCard.vue'
import IssueBehaviourCard from '@/components/Issues/IssueBehaviourCard.vue'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import coreService from '@/libs/api-services/core-service'
import IssueDetailsCard from './IssueDetailsCard.vue'

// Scripts
import issueHelpers from './issueHelpers'

export default {
  components: {
    GenericAssociator,
    BRow,
    BCol,
    BBadge,
    // BCard,
    // BLink,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,

    // SFC
    IssueDetailsCard,

    // Components
    CommentCard,
    IssueRequirementCard,
    IssueEntityCard,
    IssueBehaviourCard,

    // Modals
    IssueRequirementAllocate,
    IssueComponentAllocate,
    AssociatorGeneric,
  },
  props: {
    issueViewData: {
      type: Object,
      required: true,
    },
    openedIssueMeta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    issueHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.issueViewData.id,
          },
        },
      )
    },
  },
  setup(props, { emit }) {
    const {
      resolveClassificationIcon,
      resolveClassificationDescriptor,
      resolveSeverityColor,
      resolveStatusColor,
    } = issueHelpers()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Main watch prop
    watch(() => props.issueViewData.id, () => {
      // Reselect Issue on issue change
      store.dispatch('issues/selectIssue', props.issueViewData.id)
        .then(() => {
          emit('updated')
        })
    }, { immediate: true })

    // Associator
    // -- Buttons
    // -- -- Requirements
    const editRequirementAssociationsButton = ref('outline-secondary')
    const hoverHandlerReqAssocButton = handler => {
      if (handler) editRequirementAssociationsButton.value = 'outline-success'
      else editRequirementAssociationsButton.value = 'outline-secondary'
    }
    // -- -- Entities
    const editEntityAssociationsButton = ref('outline-secondary')
    const hoverHandlerEntAssocButton = handler => {
      if (handler) editEntityAssociationsButton.value = 'outline-primary'
      else editEntityAssociationsButton.value = 'outline-secondary'
    }

    // Edit Issue mode
    const editMode = ref(false)
    const setEditMode = value => {
      editMode.value = value
    }

    // Associator Stuff
    // -- Associated Requirements
    const associatedRequirements = ref([])

    function associatedRequirementsForAssociator() {
      const selectedIssue = store.state.issues.selected_issue
      associatedRequirements.value = []
      if (selectedIssue.requirements) {
        associatedRequirements.value = selectedIssue.requirements
          .map(x => ({
            value: {
              id: x.id,
              toSortBy: x.display_id,
            },
            text: `${x.display_id}. ${x.object_text.replace(/<\/?[^>]+(>|$)/g, '')}`,
          }))
        allRequirementsForAssociator()
      }
    }

    // -- All Requirements
    const allRequirements = ref([])

    function allRequirementsForAssociator() {
      const params = { model: store.state.model.id }
      axiosIns
        .get('/api/v2/requirements/get_requirements_simple', { params })
        .then(({ data }) => {
          allRequirements.value = data.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.text}`,
          }))
          // Remove already associated elements from the allItems array
          associatedRequirements.value.forEach((elementA, indexA) => {
            allRequirements.value.forEach((elementB, indexB) => {
              if (elementA.value.id === elementB.value.id) {
                allRequirements.value.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] fetchAll failed - ${r}`)
        })
    }

    // Run at least once
    associatedRequirementsForAssociator()

    // -- Associate Requirements
    function associateRequirements(reqs) {
      const selectedId = store.state.issues.selected_issue.id

      axiosIns
        .post('/api/v2/issues/disassociate_all_issue_requirements', { issues: [selectedId] })
        .then(() => {
          axiosIns
            .post('/api/v2/issues/associate_issues_and_requirements', {
              issues: [selectedId],
              requirements: reqs,
            })
            .then(() => {
              store.dispatch('issues/selectIssue', selectedId)
              allRequirementsForAssociator()
            })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
        })
    }

    // -- Associated Entities
    const associatedEntities = ref([])

    function fetchAssociatedEntitiesForAssociator() {
      const selectedIssue = store.state.issues.selected_issue
      associatedEntities.value = []
      if (selectedIssue.components) {
        associatedEntities.value = selectedIssue.components
        allEntitiesForAssociator()
      }
    }

    // All Requirements
    const allEntities = ref([])

    function allEntitiesForAssociator() {
      const params = { model: store.state.model.id }
      axiosIns
        .get('/api/v2/domain_model/composition', { params })
        .then(({ data }) => {
          allEntities.value = data.components
        })
    }

    // Run at least once
    fetchAssociatedEntitiesForAssociator()

    // -- Associate Entities
    function associateEntitiesIssues(entities) {
      const payload = {
        model: store.state.model.id,
        issue: store.state.issues.selected_issue.id,
        components: entities,
      }

      axiosIns
        .post('/api/v2/issues/disassociate_all_issue_components', { issue: payload.issue, model: payload.issue })
        .then(() => {
          axiosIns
            .post('/api/v2/issues/associate_issue_with_multiple_components', payload)
            .then(() => {
              store.dispatch('issues/selectIssue', payload.issue)
              allEntitiesForAssociator()
            })
            .catch(r => {
              console.error(`[AssociateEntitiesModal] submit failed - ${r}`)
            })
        })
    }

    const model = computed(() => store.state.model)
    async function fetchSharedModels() {
      return [model.value, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    }
    async function fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    }
    async function linkComponent(cpt) {
      await coreService.componentApi.createComponentIssueAllocation(cpt.id, {
        other_id: props.issueViewData.id,
      })
      fetchAssociatedEntitiesForAssociator()
      store.dispatch('issues/selectIssue', props.issueViewData.id)
        .then(() => {
          emit('updated')
        })
    }
    async function unlinkComponent(cptId) {
      await coreService.componentApi.deleteComponentAllocation(cptId, props.issueViewData.id)
      fetchAssociatedEntitiesForAssociator()
    }

    return {
      fetchSharedModels,
      fetchComponents,
      linkComponent,
      unlinkComponent,

      // UI
      perfectScrollbarSettings,

      // issueHelpers
      resolveClassificationIcon,
      resolveClassificationDescriptor,
      resolveSeverityColor,
      resolveStatusColor,

      // Vuex
      selectedIssue: computed(() => store.state.issues.selected_issue),
      // refreshIssue,

      // Associator

      // -- Buttons
      editRequirementAssociationsButton,
      hoverHandlerReqAssocButton,
      editEntityAssociationsButton,
      hoverHandlerEntAssocButton,

      // Edit Issue mode
      editMode,
      setEditMode,

      // Associator Stuff
      associatedRequirements,
      allRequirements,
      associateRequirements,
      associatedEntities,
      allEntities,
      associateEntitiesIssues,
      model,
    }
  },
}
</script>
