<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.add-issue-modal
              variant="danger"
              block
              @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
            >
              Raise Issue
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :active="isDynamicRouteActive(allIssues.route)"
                class="cursor-pointer"
                @click="clickAllIssues()"
              >
                <feather-icon
                  :icon="allIssues.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ allIssues.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Classification -->
            <h6 class="section-label mt-2 mb-1 px-2">
              Classification
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="classification in classificationFilters"
                :key="classification.title + $route.path"
                :active="isFilterClassificationActive(classification)"
                class="cursor-pointer"
                @click="clickClassification(classification)"
              >
                <feather-icon
                  :icon="classification.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ classification.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <!-- ./Classification -->

            <!-- Severity -->
            <h6 class="section-label mt-2 mb-1 px-2">
              Severity
            </h6>
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="severity in severityFilters"
                :key="severity.title + $route.path"
                :active="isFilterSeverityActive(severity)"
                class="cursor-pointer"
                @click="clickSeverity(severity)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${severity.color}`"
                />
                <span>{{ severity.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <!-- ./Severity -->

            <!-- Status -->
            <h6 class="section-label mt-2 mb-1 px-2">
              Status
            </h6>
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="status in statusFilters"
                :key="status.title + $route.path"
                :active="isFilterStatusActive(status)"
                class="cursor-pointer"
                @click="clickStatus(status)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${status.color}`"
                />
                <span>{{ status.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <!-- ./Status -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive, useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  setup(props, { emit }) {
    const { route, router } = useRouter()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const allIssues = { title: 'All Issues', icon: 'LayersIcon', route: { name: 'app_issues' } }

    const classificationFilters = [
      { title: 'Ambiguous', icon: 'HelpCircleIcon', route: { params: { classification: 'ambiguous' } } },
      { title: 'Inaccurate', icon: 'TargetIcon', route: { params: { classification: 'inaccurate' } } },
      { title: 'Incomplete', icon: 'LoaderIcon', route: { params: { classification: 'incomplete' } } },
      { title: 'Inconsistent', icon: 'ShuffleIcon', route: { params: { classification: 'inconsistent' } } },
      { title: 'Redundant', icon: 'CopyIcon', route: { params: { classification: 'redundant' } } },
      { title: 'Query', icon: 'SearchIcon', route: { params: { classification: 'query' } } },
      { title: 'Minor Query', icon: 'ArrowDownIcon', route: { params: { classification: 'minor query' } } },
      { title: 'Design Query', icon: 'FeatherIcon', route: { params: { classification: 'design query' } } },
    ]

    const severityFilters = [
      { title: 'Critical', color: 'danger', route: { params: { severity: 'critical' } } },
      { title: 'Major', color: 'danger', route: { params: { severity: 'major' } } },
      { title: 'Minor', color: 'warning', route: { params: { severity: 'minor' } } },
      { title: 'Negligible', color: 'secondary', route: { params: { severity: 'negligible' } } },
      { title: 'Query', color: 'info', route: { params: { severity: 'query' } } },
      { title: 'Minor Query', color: 'info', route: { params: { severity: 'minor query' } } },
      { title: 'Design Query', color: 'info', route: { params: { severity: 'design query' } } },
    ]

    const statusFilters = [
      { title: 'New', color: 'danger', route: { params: { status: 'new' } } },
      { title: 'Open', color: 'warning', route: { params: { status: 'open' } } },
      { title: 'Deferred', color: 'info', route: { params: { status: 'deferred' } } },
      { title: 'Withdrawn', color: 'secondary', route: { params: { status: 'withdrawn' } } },
      { title: 'Closed', color: 'secondary', route: { params: { status: 'closed' } } },
    ]

    let classification = ''
    let severity = ''
    let status = ''
    const routeToFilter = () => {
      router.push({
        name: 'app_issues_filter',
        params: {
          filter: `${classification}%7C${severity}%7C${status}`,
        },
      })
    }

    const clickAllIssues = () => {
      classification = ''
      severity = ''
      status = ''
      router.push(allIssues.route)
      emit('close-left-sidebar')
    }

    const clickClassification = clicked => {
      classification = classification === clicked.route.params.classification ? '' : clicked.route.params.classification
      routeToFilter()
      emit('close-left-sidebar')
    }

    const clickSeverity = clicked => {
      severity = severity === clicked.route.params.severity ? '' : clicked.route.params.severity
      routeToFilter()
      emit('close-left-sidebar')
    }

    const clickStatus = clicked => {
      status = status === clicked.route.params.status ? '' : clicked.route.params.status
      routeToFilter()
      emit('close-left-sidebar')
    }

    const isFilterClassificationActive = c => classification === c.route.params.classification && classification !== ''
    const isFilterSeverityActive = c => severity === c.route.params.severity && severity !== ''
    const isFilterStatusActive = c => status === c.route.params.status && status !== ''

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,

      // Classification & Severity Filters
      allIssues,
      classificationFilters,
      severityFilters,
      statusFilters,
      clickAllIssues,
      clickClassification,
      clickSeverity,
      clickStatus,
      isFilterClassificationActive,
      isFilterSeverityActive,
      isFilterStatusActive,
    }
  },
}
</script>
